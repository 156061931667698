import React from 'react';

function NickelData({ nickelData }) {
    const getStyleForChange = (change) => {
        if (change < 0) {
            return { backgroundColor: '#ffcccc', color: 'black' }; // Softer red for negative change
        } else if (change > 0) {
            return { backgroundColor: '#ccffcc', color: 'black' }; // Softer green for positive change
        } else {
            return {};
        }
    };

    const formatCurrency = (value) => {
        return `$${value.toFixed(0).replace(/\d(?=(\d{3})+$)/g, '$&,')}`;
    };

    return (
        <div>
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Equity</th>
                        <th>Loans</th>
                        <th>%Change (Equity)</th>
                        <th>%Change (Loans)</th>
                    </tr>
                </thead>
                <tbody>
                    {nickelData.map((item, index) => (
                        <tr key={index}>
                            <td>{item.date}</td>
                            <td>{formatCurrency(item.equity)}</td>
                            <td>{formatCurrency(item.loans)}</td>
                            <td>{(item["%Change (equity)"] * 100).toFixed(2)}%</td>
                            <td>{(item["%Change (loans)"] * 100).toFixed(2)}%</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default NickelData;
