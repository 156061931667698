// Wincent.js
import React from 'react';

function Wincent({ wincentData, displayCount, setDisplayCount }) {
    const getStyleForChange = (change) => {
        if (change < 0) {
            return { backgroundColor: '#ffcccc', color: 'black' }; // Softer red for negative change
        } else if (change > 0) {
            return { backgroundColor: '#ccffcc', color: 'black' }; // Softer green for positive change
        } else {
            return {};
        }
    };

    return (
        <div>
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Total NAV (US$ Millions)</th>
                        <th>Total Net Debt (US$ Millions)</th>
                        <th>% Change (NAV)</th>
                        <th>% Change Net Debt</th>
                        <th>Change in NAV</th>
                        <th>Change in Debt</th>
                    </tr>
                </thead>
                <tbody>
                    {wincentData.slice(0, displayCount).map((item, index) => (
                        <tr key={index}>
                            <td>{item.date}</td>
                            <td>{item['totalNavUs$ (millions)']}</td>
                            <td>{item['totalNetDebtUs$ (millions)']}</td>
                            <td style={getStyleForChange(item['%Change (nav)'])}>
                                {(item['%Change (nav)'] * 100).toFixed(2)}%
                            </td>
                            <td style={getStyleForChange(item['%ChangeNetDebt'])}>
                                {(item['%ChangeNetDebt'] * 100).toFixed(2)}%
                            </td>
                            <td style={getStyleForChange(item['changeInNav'])}>
                                {item['changeInNav'].toFixed(2)}
                            </td>
                            <td style={getStyleForChange(item['changeInDebt'])}>
                                {item['changeInDebt'].toFixed(2)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {displayCount < wincentData.length && (
                <button onClick={() => setDisplayCount(displayCount + 10)}>See More</button>
            )}
        </div>
    );
}

export default Wincent;

