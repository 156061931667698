import React, { useEffect, useState } from 'react';

function ADOC() {
  const [adoCstats, setAdoCstats] = useState([]);

  useEffect(() => {
    // Make an API request to fetch ADOC data
    fetch('https://api.sheety.co/cd8d5925e10bc14b1b986b20a83c50f0/dashboard/adoCstats', {
      headers: {
        'Authorization': 'Bearer 49*Z6XA2fqAd9dIC*UPUaeJd'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setAdoCstats(data.adoCstats);
      })
      .catch((error) => {
        console.error('Error fetching ADOC data:', error);
      });
  }, []);

  return (
    <div>
      <h1>ADOC Stats</h1>
      <table>
        <thead>
          <tr>
            <th>Fund Size</th>
            <th>Capital Called</th>
            <th>Capital Call %</th>
            <th>Cash on Hand</th>
            <th>Total Claim Notional</th>
            <th>Total Purchase Price (raw)</th>
          </tr>
        </thead>
        <tbody>
          {adoCstats.map((item, index) => (
            <tr key={index}>
              <td>{item.fundSize}</td>
              <td>{item.capitalCalled}</td>
              <td>{(item['capitalCall %'] * 100).toFixed(1)}%</td>
              <td>{item.cashOnHand}</td>
              <td>{item.totalClaimNotional}</td>
              <td>{item['totalPurchasePrice (raw):']}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ADOC;
