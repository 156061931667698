import React from 'react';

function MarketUpdates({ marketPrices }) {
    const greyStyle = { backgroundColor: '#d3d3d3' };

    const getStyleForPercentage = (percentage) => {
        if (percentage < 0) {
            return { backgroundColor: '#ffcccc', color: 'black' }; // Softer red
        } else if (percentage > 0) {
            return { backgroundColor: '#ccffcc', color: 'black' }; // Softer green
        } else {
            return {};
        }
    };

    return (
        <div>
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Value (USD)</th>
                        <th>24H Change</th>
                        <th>24H Change (%)</th>
                        <th>Last Updated</th>
                    </tr>
                </thead>
                <tbody>
                    {marketPrices.map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td>
                  {item.name === '10-year treasury' && item['value (usd)'] !== undefined
                    ? `${(item['value (usd)'] * 100).toFixed(2)}%`
                    : item['value (usd)'] !== undefined && typeof item['value (usd)'] === 'number'
                    ? item['value (usd)'].toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : 'N/A'}
                </td>
                <td style={item.name === '10-year treasury' ? greyStyle : null}>
                  {item.name !== '10-year treasury' && item['24HChange'] !== undefined
                    ? typeof item['24HChange'] === 'number'
                      ? item['24HChange'].toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : 'N/A'
                    : null}
                </td>
                <td style={item.name === '10-year treasury' ? greyStyle : getStyleForPercentage(item['24HChange (%)'])}>
                  {item.name !== '10-year treasury' && item['24HChange (%)'] !== undefined
                    ? typeof item['24HChange (%)'] === 'number'
                      ? `${(item['24HChange (%)'] * 100).toFixed(2)}%`
                      : 'N/A'
                    : null}
                </td>
                <td>{item.lastUpdated || 'N/A'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
};

export default MarketUpdates;

