import React, { useState, useEffect } from 'react';

function LoanBook() {
  const [loanData, setLoanData] = useState([]);
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState('loanNo'); // Default sorting by loanNo

  useEffect(() => {
    // Replace this URL with your actual API endpoint and bearer token
    const apiUrl = 'https://api.sheety.co/cd8d5925e10bc14b1b986b20a83c50f0/dashboard/loanBook';
    const bearerToken = '49*Z6XA2fqAd9dIC*UPUaeJd'; // Your bearer token

    fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${bearerToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((json) => {
        setLoanData(json.loanBook); // Assuming the API returns an array of loan objects under "loanBook"
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(amount);
  };

  const formatInterestRate = (rate) => {
    return (rate * 100).toFixed(2) + '%';
  };

  // Sort the loan data by NAV date if sortBy is 'navDate'
  if (sortBy === 'navDate') {
    loanData.sort((a, b) => {
      const dateA = new Date(a.navDate.split('/').reverse().join('/'));
      const dateB = new Date(b.navDate.split('/').reverse().join('/'));
      return dateA - dateB;
    });
  }

  if (error) {
    return <div>Error: Unable to fetch loan data. Please try again later.</div>;
  }

  return (
    <div>
      <h2>Loan Book</h2>
      <div>
        Sort By:
        <select
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
        >
          <option value="loanNo">Loan No</option>
          <option value="loanAmount">Loan Amount</option>
          <option value="currency">Currency</option>
          <option value="interestRate">Interest Rate</option>
          <option value="navDate">Nav Date</option>
          <option value="interestDue">Interest Due</option>
        </select>
      </div>
      <table>
        <thead>
          <tr>
            <th>Loan No</th>
            <th>Loan Amount</th>
            <th>Currency</th>
            <th>Interest Rate (%)</th>
            <th>Nav Date</th>
            <th>Interest Due</th>
          </tr>
        </thead>
        <tbody>
          {loanData.map((loan) => (
            <tr key={loan.id}>
              <td>{loan.loanNo}</td>
              <td>{formatCurrency(loan.loanAmount)}</td>
              <td>{loan.currency}</td>
              <td>{formatInterestRate(loan['interestRate (%)'])}</td>
              <td>{loan.navDate}</td>
              <td>{formatCurrency(loan.interestDue)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default LoanBook;
