import React from 'react';

function DefiYield({ defiYield }) {
    return (
      <div>
        <table>
          <thead>
            <tr>
              <th>Instrument</th>
              <th>Deposit</th>
              <th>Borrow</th>
              <th>Additional Per Loop</th>
              <th>Collateralization Ratio</th>
              <th>Max LTV</th>
              <th>Loop Yield</th>
              <th>Highest Yield</th>
              <th>Pool Size</th> {/* New Header */}
              <th>Last Update</th>
            </tr>
          </thead>
          <tbody>
            {defiYield.map((item, index) => (
              <tr key={index}>
                <td>{item.instrument}</td>
                <td>{(item.deposit * 100).toFixed(2)}%</td>
                <td>{(item.borrow * 100).toFixed(2)}%</td>
                <td>{(item.additionalPerLoop * 100).toFixed(2)}%</td>
                <td>{(item.collateralizationRatio * 100).toFixed(2)}%</td>
                <td>{item.maxLtv.toFixed(2)}</td>
                <td>{(item.loopYield * 100).toFixed(2)}%</td>
                <td>{(item.highestYield * 100).toFixed(2)}%</td>
                <td>{item.poolSize.toLocaleString()} {/* Display Pool Size */}</td>
                <td>{item.lastUpdate}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
}

export default DefiYield;