import React, { useState } from 'react';

function SpotFutures({ spotFutures }) {
  const [sortBy, setSortBy] = useState('date'); // Default sorting by date

  // Sort the spotFutures data based on the selected sortBy option
  const sortedSpotFutures = [...spotFutures].sort((a, b) => {
    if (sortBy === 'date') {
      const dateA = new Date(a.date.split('/').reverse().join('/'));
      const dateB = new Date(b.date.split('/').reverse().join('/'));
      return dateA - dateB;
    } else if (sortBy === 'notionalSpread') {
      return a.notionalSpread - b.notionalSpread;
    } else if (sortBy === 'annualizedSpread') {
      return a.annualizedSpread - b.annualizedSpread;
    } else {
      return 0; // Default case: no sorting
    }
  });

  return (
    <div>
      <h2>Spot Futures</h2>
      <div>
        Sort By:
        <select
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
        >
          <option value="date">Date</option>
          <option value="instrument">Instrument</option>
          <option value="daysToExpiry">Days to Expiry</option>
          <option value="notionalSpread">Notional Spread</option>
          <option value="annualizedSpread">Annualized Spread</option>
          <option value="lastUpdated">Last Updated</option>
        </select>
      </div>
      <table>
        <thead>
          <tr>
            <th>Instrument</th>
            <th>Date</th>
            <th>Days to Expiry</th>
            <th>Notional Spread($)</th>
            <th>Annualized Spread</th>
            <th>Last Updated</th>
          </tr>
        </thead>
        <tbody>
          {sortedSpotFutures.map((item, index) => (
            <tr key={index}>
              <td>{item.instrument}</td>
              <td>{item.date}</td>
              <td className={item.instrument.includes('PERPETUAL') ? 'greyed-out' : ''}>
                {item.instrument.includes('PERPETUAL') ? 'N/A' : item.daysToExpiry}
              </td>
              <td>${item.notionalSpread}</td>
              <td>{(item.annualizedSpread * 100).toFixed(2)}%</td>
              <td>{item.lastUpdated}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default SpotFutures;

