import React, { useState } from 'react';

function PasswordProtection({ onPasswordEntry }) {
  const [password, setPassword] = useState('');
  const correctPassword = 'v!JFJQ7H1d$d%zCk'; // Set the correct password here

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if the entered password matches the correct password
    if (password === correctPassword) {
      // Password is correct, trigger the callback
      onPasswordEntry();
    } else {
      // Password is incorrect, show an error message or handle it as needed
      alert('Incorrect password. Please try again.');
    }
  };

  return (
    <div>
      <h1>Enter Password</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default PasswordProtection;
